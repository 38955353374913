<template>
  <div class="ApplicationUse">
    <div class="header"></div>
    <div class="contion">
      <div class="left">
        <img src="@/assets/image/applicationUse/h5.png" alt="" class="h5-img"
          style="width: 2.4rem; height: 2.4rem;margin-top: 1rem;">
        <div class="div1">云智aPaaS平台 加速伙伴供应链物流数字化</div>
        <div class="div2 font-d">聚焦行业核心场景，打造行业标杆应用</div>
        <div class="div3 font-d">全栈自研的AI大底座，满足产业对智算基础设施的需求</div>
        <div class="div4 font-d">先进的技术和丰富的解决方案，与实体经济深度融合，共创价值</div>
        <div class="div5"></div>
        <img style="width: 4.28rem; height: 1.22rem;" src="~assets/image/applicationUse/img1.png" />
      </div>
      <div class="right" v-if="isRegister">
        <div class="title">申请试用</div>
        <div class="steps">
          <div class="st " :class="cursteps > 0 ? 'blue' : ''">
            <span class="index">1</span>
            <span class="font">填写信息</span>
          </div>
          <div class="line"></div>
          <div class="st" :class="cursteps > 1 ? 'blue' : ''">
            <span class="index">2</span>
            <span class="font">信息审核</span>
          </div>
          <div class="line"></div>
          <div class="st" :class="cursteps > 2 ? 'blue' : ''">
            <span class="index" :style="{ backgroundColor: isRejected ? 'gray' : '#0560D6' }">3</span>
            <span class="font">登录试用</span>
          </div>
        </div>
        <div class="form-l">
          <el-form ref="form" :model="form" :rules="rules" label-width="120px" v-if="cursteps == 1"
            class="message-form-1">
            <el-form-item label="企业名称:" prop="company_name" required>
              <el-input v-model="form.company_name"></el-input>
            </el-form-item>
            <el-form-item label="企业地址:" prop="company_address" required>
              <el-input v-model="form.company_address"></el-input>
            </el-form-item>
            <el-form-item label="企业所属行业:" prop="company_industry" required>
              <el-select v-model="form.company_industry" multiple placeholder="请选择企业所属行业">
                <el-option label="生鲜" value="生鲜"></el-option>
                <el-option label="医药批发" value="医药批发"></el-option>
                <el-option label="医药连锁" value="医药连锁"></el-option>
                <el-option label="医疗器械" value="医疗器械"></el-option>
                <el-option label="酒水" value="酒水"></el-option>
                <el-option label="医疗生产" value="医疗生产"></el-option>
              </el-select>
              <!-- <el-input v-model="form.company_industry"></el-input> -->
            </el-form-item>
            <el-form-item label="联系人姓名:" prop="apply_name" required>
              <el-input v-model="form.apply_name"></el-input>
            </el-form-item>
            <el-form-item label="联系电话:" prop="mobile" required>
              <el-input v-model="form.mobile"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button style="width: 100%;" type="primary" @click="submit">提交</el-button>
            </el-form-item>
          </el-form>
          <div v-else-if="cursteps == 2" class="message-form">
            <div class="iconcheck">
              <img src="../assets/image/applicationUse/register.png">
            </div>
            <div class="span1">已注册待审核</div>
            <div class="span2">后台审核通过后，您将通过短信收到登录账号/密钥</div>
          </div>
          <div v-else-if="cursteps == 3" class="message-form">
            <div v-if="!isRejected">
              <div class="iconcheck">
                <img src="../assets/image/applicationUse/checkPass.png">
              </div>
              <div class="span1">审核通过</div>
              <div class="span2">请点击右上角登录开发者平台</div>
            </div>
            <div v-else>
              <div class="iconcheck">
                <img src="../assets/image/applicationUse/checkReject.png">
              </div>
              <div class="span1">审核驳回</div>
              <div class="span2">{{ rejectResin }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-register-right" v-else>
        <div class="title" style="text-align: center;"> 注册申请试用 </div>
        <div class="tab-container">
          <el-tabs v-model="activeName" stretch>
            <el-tab-pane label="手机验证码登录" name="first">
              <el-form :model="formData" ref="ruleForm" class="demo-ruleForm" label-position="left">
                <el-form-item label="手机号" prop="name">
                  <el-input v-model="formData.phone" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="短信验证码" prop="name">
                  <div class="message-box">
                    <el-input v-model="formData.smsVerify" placeholder="请输入您的短信验证码"></el-input>
                    <div style="width: 0.06rem;"></div>
                    <div class="btn">
                      <div class="text time" v-if="codeTimeFlag"> {{ codeTime }} 秒</div>
                      <div class="text" @click="getCode" v-else> 获取验证码 </div>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <div type="primary" @click="validform" class="confirm-btn">登录</div>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="微信扫码登录" name="second">
              <div class="wechat-box">
                <wxlogin appid="wxb9746adf95e5b52d" scope='snsapi_login' :redirect_uri="redirect_uri" :href="href" />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueQr from 'vue-qr'
import wxlogin from 'vue-wxlogin'
export default {
  components: {
    vueQr,
    wxlogin
  },
  computed: {
    redirect_uri: () => {
      return `http%3A%2F%2Fwww.jzinz.com%2F%23%2FapplicationUse`
    }
  },
  data () {
    return {
      isRejected: false,
      rejectResin: '',
      href: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7CiAgd2lkdGg6IDIwMHB4OwogIG1hcmdpbi10b3A6IDE1cHg7CiAgYm9yZGVyOiAxcHggc29saWQgI2UyZTJlMjsKfQo=',
      wechatCode: '',
      rules: {
        company_name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
          { required: true, message: '请输入企业名称', trigger: 'change' },
        ],
        company_address: [
          { required: true, message: '请输入企业地址', trigger: 'blur' },
          { required: true, message: '请输入企业地址', trigger: 'change' },
        ],
        company_industry: [
          { required: true, message: '请输入企业所属行业', trigger: 'change' }
        ],
        apply_name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { required: true, message: '请输入姓名', trigger: 'change' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { required: true, message: '请输入手机号', trigger: 'change' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ]
      },
      limitCodeTime: 60,
      codeTime: 60,
      codeTimeFlag: false,
      form: { company_name: '', company_address: '', company_industry: [], apply_name: '', mobile: '' },
      radio: '',
      cursteps: 1,
      isRegister: false,
      activeName: 'first',
      validateCode: '',
      phone: '',
      formData: {
        phone: '',
        smsVerify: ''
      },
      listData: [
        {
          text: 'A：立即沟通',
          label: '1'
        },
        {
          text: 'B：今天18:00前联系我',
          label: '2'
        },
        {
          text: 'C：明天联系我:00前联系我',
          label: '3'
        },
      ]
    }
  },
  methods: {
    checkTelephone (telephone) {
      var reg = /^[1][3,4,5,7,8][0-9]{9}$/
      if (!reg.test(telephone)) {
        return false
      } else {
        return true
      }
    },
    async submit () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.unionid) {
            this.form.unionid = this.unionid
          }
          let params = JSON.parse(JSON.stringify(this.form))
          params.company_industry = params.company_industry.toString()
          const res = await this.http.service({
            url: '/tdp/script/Invoke/saveApplyInfo',
            data: { params: params }
          })
          this.unionid = ''
          this.form.unionid = ''
          if (res.code !== 200) {
            this.$message.warning(res.message)
            return
          } else {
            this.phone = this.form.mobile
            this.$message.success('已提交申请!')
            this.cursteps = 2
            this.selectstaus()
          }
        } else {
          return false
        }
      })
    },
    async selectstaus () {
      // 在组件中设置 localStorage
      if (this.form.mobile) {
        localStorage.setItem('curphone', this.form.mobile)
      }
      const res = await this.http.service({
        url: '/tdp/script/Invoke/ApplyUserSelect',
        data: {
          params: {
            mobile: this.form.mobile
          }
        },
        headers: {
          tenantld: '1'
        }
      })
      this.isRejected = false
      if (res.code !== 200) {
        this.$message.error(res.message)
        return
      } else {
        if (!res.data[0]) return
        this.isRegister = true
        const userInfo = res.data[0]
        localStorage.setItem('userInfo', JSON.stringify(res.data[0]))
        localStorage.setItem('loginStatus', 'true')
        this.$root.$emit('loginSuccess', userInfo)
        switch (res.data[0].apply_status) {
          case '0':
            this.cursteps = 2
            break
          case '1':
            this.cursteps = 3 //已审核
            break
          case '-1':
            this.cursteps = 3 //驳回
            // this.$message.error('审核被驳回!')
            this.isRejected = true
            this.rejectResin = res.data[0].remark
            break
          case '2': // 已注册
            this.cursteps = 3
            // this.$message.success('该信息已注册!')
            break
          default:
        }
      }
    },
    async getCode () {
      if (this.codeTimeFlag) return
      if (!this.formData.phone) {
        this.$message.warning('联系电话不能为空')
        return
      }
      if (!this.checkTelephone(this.formData.phone)) {
        this.$message.warning('联系电话格式不正确')
        return
      }
      const res = await this.http.service({
        url: '/tdp/msg/smsVerify/send',
        data: {
          bizType: 'resolvePlan',
          "templateId": 1,
          verifyLength: 4,
          "to": this.formData.phone,
          verifyValidSecond: this.limitCodeTime,
          verifyResendSecond: this.limitCodeTime,
        }
      })
      if (res.code !== 200) {
        this.$message.warning(res.message)
        return
      }
      this.$message.success('验证码发送成功')
      //   开始计时
      this.startCodeTime()
      this.validateCode = res.data
    },
    startCodeTime () {
      this.timer = setInterval(() => {
        this.codeTime = this.codeTime - 1
        if (this.codeTime > 0) {
          this.codeTimeFlag = true
        } else {
          clearInterval(this.timer)
          this.timer = null
          this.codeTime = this.limitCodeTime
          this.codeTimeFlag = false
          this.validateCode = ''
        }
      }, 1000)
    },
    async validform () {
      if (!this.formData.phone) {
        this.$message.warning('联系电话不能为空')
        return
      }
      if (!this.checkTelephone(this.formData.phone)) {
        this.$message.warning('联系电话格式不正确')
        return
      }
      const res = await this.http.service({
        url: '/tdp/msg/smsVerify/valid',
        data: {
          requestId: this.validateCode,
          smsVerify: this.formData.smsVerify
        }
      })
      if (res.code !== 200) {
        this.$message.warning(res.message)
        return
      }
      // this.$message.success('登录成功!')
      this.isRegister = true

      localStorage.setItem('curphone', this.formData.phone)
      this.form.mobile = this.formData.phone
      this.selectstaus()
    },
    GetQueryString (name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
      var r = window.location.search.substr(1).match(reg) //获取url中"?"符后的字符串并正则匹配
      var context = ""
      if (r != null)
        context = r[2]
      reg = null
      r = null
      return context == null || context == "" || context == "undefined" ? "" : context
    },
    async handleCode () {
      const _this = this
      const wechatCode = localStorage.getItem('wechatCode')
      if (!wechatCode) return
      localStorage.setItem('curphone', '')
      //   清除数据
      const res = await this.http.service({
        url: `tdp/auth/portal/wechat/token?code=${wechatCode}`
      })
      localStorage.setItem('wechatCode', '')
      if (res.code !== 200) {
        this.$message.warning(res.message)
        return
      }
      const unionid = res.data.unionid
      if (!unionid) {
        return
      }
      const res2 = await this.http.service({
        url: `tdp/data/gateway/v2/UnionidSelect/select`,
        data: {
          unionid
        }
      })

      if (res2.code !== 200) {
        this.$message.warning(res2.message)
        return
      }
      //   未绑定
      if (res2.data.length === 0) {
        _this.$prompt('联系电话', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(async ({ value }) => {
          if (!_this.checkTelephone(value)) {
            _this.$message.warning('联系电话格式不正确')
            return
          }
          const res = await _this.http.service({
            url: '/tdp/script/Invoke/bindingUnionid',
            data: {
              "params": { "mobile": value, unionid }
            }
          })
          if (res.code === 1000) {
            _this.$message.info('请先注册申请试用')
            _this.isRegister = false
            _this.activeName = 'first'
            _this.unionid = unionid
            return
          }
          if (res.code !== 200) {
            _this.$message.warning(res.message)
            return
          }
          _this.form.mobile = res.data.mobile
          localStorage.setItem('curphone', res.data.mobile)
          _this.selectstaus()
        })
      } else {
        _this.form.mobile = res2.data[0].mobile
        localStorage.setItem('curphone', res2.data[0].mobile)
        _this.selectstaus()
      }
    }
  },
  watch: {
    '$route.query.code' (code) {
      if (code) {
        localStorage.setItem('wechatCode', code)
        // this.handleCode()
        window.location.href = location.origin + '/#/applicationUse'
        window.location.reload()
      }
    }
  },
  mounted () {
    // const code = this.GetQueryString('code') || this.$route.query.code
    // if (code) {
    //   localStorage.setItem('wechatCode', code)
    //   window.location.href = location.origin + '/#/applicationUse'
    // }
    this.handleCode()
    // 在组件中获取 localStorage 的值
    this.form.mobile = localStorage.getItem('curphone')
    if (this.form.mobile) {
      this.isRegister = true
      this.selectstaus()
    }
    // if (localStorage.getItem('userInfo')) {
    //   const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
    //   this.form.mobile = userInfo.mobile
    //   localStorage.setItem('curphone', userInfo.mobile)
    //   this.isRegister = true
    //   this.selectstaus()
    // }
  }
}
</script> 
 
<style lang='scss' scoped>
.ApplicationUse {
  width: 100%;
  height: 100%;

  .header {
    height: 1.02rem;
    background-color: #0560D6;
  }

  .contion {
    display: flex;

    .left {
      background: url('~@/assets/image/applicationUse/apbg.png') no-repeat;
      height: 8.8rem;
      width: 7.22rem;
      text-align: center;

      .div1 {
        margin-top: .3rem;
        font-size: 0.28rem;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #191A24;
        line-height: 0.33rem;
      }

      .div2 {
        margin-top: 0.25rem;
      }

      .font-d {
        font-size: 0.14rem;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #545B66;
        line-height: 0.28rem;
      }

      /* .div5 {
        width: 4.9rem;
        height: 0.01rem;
        background: #C5CCDB;
        border-radius: 0rem 0rem 0rem 0rem;
        opacity: 1;
        margin: 0 auto;
        margin-top: 0.35rem;
      } */

      img {

        margin-top: 0.6rem;
        /* margin-bottom: 2.37rem; */
      }
    }

    .right {
      flex: 1;

      .title {
        margin-top: 1.2rem;
        width: 100%;
        text-align: center;
        height: 0.39rem;
        font-size: 0.28rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #191A24;
        line-height: 0.33rem;
      }

      .steps {
        margin: 0.44rem 0 0 2.36rem;
        display: flex;
        width: 7rem;

        .line {
          width: 1.92rem;
          height: 0.03rem;
          margin: auto 0;
          background: #c0c4cc;
          border-radius: 0rem 0rem 0rem 0rem;
          opacity: 1;
        }

        .st {
          display: flex;

          .index {
            display: block;
            width: 0.32rem;
            height: 0.32rem;
            border-radius: 50%;
            opacity: 1;
            // border-color: red;
            border: 0.5px solid #c0c4cc;
            text-align: center;
            font-size: 0.16rem;
            line-height: 0.32rem;
            color: #c0c4cc;
          }

          .font {
            height: 0.32rem;
            font-size: 0.16rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 0.32rem;
            ;
            color: #c0c4cc;
          }
        }
      }

      .form-l {
        width: 7rem;
        margin: 0 auto;
        padding-top: 0.84rem;

        .message-form-1 {
          width: 5rem;
          margin-left: 0.8rem;
        }

        ::v-deep .el-select {
          width: 100%;
        }
      }

      .message-form {
        text-align: center;
        display: flex;
        flex-direction: column;

        .iconcheck {
          width: 1rem;
          height: 1rem;
          opacity: 1;
          margin: 0 auto;
          margin-bottom: .2rem;
        }

        .span1 {
          margin-top: 0.21rem;
          height: .32rem;
          font-size: .24rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: .32rem;
        }

        .span2 {
          margin-top: 0.06rem;
          height: .22rem;
          font-size: .14rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.43);
          line-height: .22rem;
        }

        .message-form-2 {
          display: flex;
          justify-content: space-between;


          .message-form-2-left {
            flex: 1;
            text-align: left;

            .message-form-2-title {
              text-align: left;
              height: .28rem;
              font-size: .2rem;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #191A24;
              line-height: .23rem;
              margin-bottom: .4rem;
            }

            .item-list {
              display: flex;
              flex-direction: column;

              .list {
                display: flex;
                margin-bottom: .18rem;

                .text {
                  height: 0.22rem;
                  font-size: 0.12rem;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #616972;
                }
              }
            }

            .text {
              height: .21rem;
              font-size: .12rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #C0C4CC;
              line-height: .21rem;
            }

            .btn {
              margin-top: .2rem;
            }
          }

          .message-form-2-right {
            .message-form-2-right-container {
              margin-top: .2rem;

              .text1 {
                height: .22rem;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #565E66;
                line-height: .22rem;
              }

              .text2 {
                height: .22rem;
                font-size: .14rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #565E66;
                line-height: .22rem;
              }
            }
          }
        }
      }
    }

    .no-register-right {
      margin: 2rem auto 0;

      .title {
        height: 39px;
        font-size: 28px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #191A24;
        line-height: .33rem;
        margin-bottom: .6rem;
      }

      .tab-container {
        width: 5rem;

        ::v-deep .el-tab-pane#pane-first {
          padding-top: .5rem;
        }

        ::v-deep .el-tabs__item {
          padding: 0 .3rem;
        }
      }

      ::v-deep .el-form-item {
        display: flex;
        flex-direction: column;
        /* margin-bottom: 0.15rem; */

        .confirm-btn {
          color: #FFFFFF;
          height: .4rem;
          background: #0560D6;
          border-radius: .04rem;
          opacity: 1;
          text-align: center;
          line-height: .4rem;
          cursor: pointer;
        }

        .message-box {
          display: flex;

          .btn {
            min-width: 1.42rem;
            max-width: 1.42rem;
            height: .47rem;
            background: #FFFFFF;
            border-radius: .04rem;
            opacity: 1;
            border: .01rem solid #0560D6;
            cursor: pointer;
            margin: auto;
            // margin-left: .06rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .text {
              /* width: 0.9rem; */
              height: .21rem;
              font-size: .12rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #0560D6;
              line-height: .21rem;
              text-align: center;

              &.time {
                /* color: orange; */
                font-size: .2rem;
              }
            }
          }

        }

        .el-form-item__label {
          height: .21rem;
          font-size: .16rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #616972;
          line-height: .21rem;
          margin-bottom: 0.06rem;
        }

        .el-form-item__content {
          margin-left: 0 !important;

          .el-input__inner {
            height: 0.47rem;
          }

          .el-select {
            width: 100%;
          }
        }
      }

      .wechat-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .text1 {
          height: .2rem;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #565E66;
          line-height: .2rem;
          float: left;
        }

        .text2 {
          height: .2rem;
          font-size: .14rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #191A24;
          line-height: .2rem;
          float: right;
          cursor: pointer;
        }
      }
    }
  }
}

.blue {
  .index {
    background-color: #0560D6;
  }

  .font {
    color: #303133 !important;
  }

}
</style>
